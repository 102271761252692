@use "sass:map";
@import 'variables';

// =========================================================
// Generic
// =========================================================

//SURFACE
$surface: (
  "black": #050607,
  "grey950": #14161A,
  "grey900": #202227,
  "grey800": #292D35,
  "grey700": #333842,
  "grey600": #4A555F,
  "grey500": #63717E,
  "grey400": #73808B,
  "grey300": #828D98,
  "grey200": #B1B8BF,
  "grey100": #D8DBDF,
  "grey50": #E8EAEC,
  "grey25": #F9F9FA,
  "white": #FFFFFF
);

// Desktop
$desktop: (
  light: (
    "primary": #025262,
    "hover": #036D83,
    "border": #81B6C1,
    "highlight": #F2F8F9
  ),
  dark: (
    "primary": #147485,
    "hover": #1B9AB2,
    "border": #8DCCD8,
    "highlights": #F4FAFB
  )
);
// Success
$success: (
  light: (
    "dark": #246B3C,
    'default': #2D864B,
    "bright": #96C3A5,
    "brighter": #F4F9F6
  ),
  dark: (
    "dark": #36784D,
    "default": #53B977,
    "bright": #A9DCBB,
    "brighter": #EEF8F1
  ) 
);
// Warning
$warning: (
  light: (
    "dark": #C28200,
    'default': #EFA611,
    "bright": #FBD280,
    "brighter": #FFFBF2
  ),
  dark: (
    "dark": #867416,
    "default": #F4D328,
    "bright": #FAE993,
    "brighter": #FEFBE9
  ) 
);
// Error
$error: (
  light: (
    "dark": #AD230A,
    'default': #D82C0D,
    "bright": #EC9586,
    "brighter": #FDF4F3
  ),
  dark: (
    "dark": #90432D,
    "default": #DE6745,
    "bright": #EFB3A2,
    "brighter": #FCF0EC
  ) 
);
// Info
$info: (
  light: (
    "dark": #0A6396,
    "default": #0D7CBB,
    "bright": #86BEDD,
    "brighter": #F3F8FC
  ),
  dark: (
    "dark": #2B889C,
    "default": #37ACC5,
    "bright": #9BD6E2,
    "brighter": #F5FBFC
  ) 
);
$shadow: 0px 3px 5px 0px rgba(5, 6, 7, 0.3);
// Focus
$focus: (
  light: (
    "default": 4px solid #81B6C1,
    "success": 4px solid #7FBFAF,
    "error": 4px solid #EC9586
  ),
  dark: (
    "default": 4px solid #147485,
    "success": 4px solid #7FBFAF,
    "error": 4px solid #EC9586
  ) 
);

@function desktop($colour, $n) {
  @return map-get(map-get($desktop, $colour), $n);
}
@function success($colour, $n) {
  @return map-get(map-get($success, $colour), $n);
}
@function warning($colour, $n) {
  @return map-get(map-get($warning, $colour), $n);
}
@function error($colour, $n) {
  @return map-get(map-get($error, $colour), $n);
}
@function info($colour, $n) {
  @return map-get(map-get($info, $colour), $n);
}
@function focus($colour, $n) {
  @return map-get(map-get($focus, $colour), $n);
}

$access: #e5173f !default;
$atoll: #036d83 !default;
$denim: #0f7db5 !default;
$backgroundDM: #1c1e21 !default;
$backgroundMobile: #ffffff !default;
$backgroundMobileDM: #121212 !default;
$black: map.get($surface, "black");
$blackDM: map.get($surface, "white");
$body: #7d7d80 !default;
$border: map.get($surface, "grey300");
$borderFocused: map.get($surface, "grey300");
$borderFilled: map.get($surface, "grey300");
$borderDisabled: map.get($surface, "grey100");
$borderError: error($theme_option, "default");
$borderSuccess: success($theme_option, "default");
//TODO when have DM colours
$borderDM: map.get($surface, "grey800");
$borderEmptyDM: map.get($surface, "grey800");
$borderFocusedDM: map.get($surface, "grey700");
$borderFilledDM: map.get($surface, "grey800");
$borderDisabledDM: map.get($surface, "grey800");
$borderErrorDM: map.get($surface, "grey800");
$borderSuccessDM: map.get($surface, "grey800");
$component: map.get($surface, "grey700");
$componentPlaceholder: map.get($surface, "grey400");
$componentFocused: map.get($surface, "grey700");
$componentDisabled: map.get($surface, "grey400");
$componentError: map.get($surface, "grey700");
$componentSuccess: map.get($surface, "grey700");
//TODO when have DM colours
$componentDM: map.get($surface, "grey700");
$componentPlaceholderDM: map.get($surface, "grey400");
$componentFocusedDM: map.get($surface, "grey700");
$componentDisabledDM: map.get($surface, "grey400");
$componentErrorDM: map.get($surface, "grey700");
$componentSuccessDM: map.get($surface, "grey700");
$componentBackground: map.get($surface, "white");
$componentBackgroundFocused: map.get($surface, "white");
$componentBackgroundFilled: map.get($surface, "white");
$componentBackgroundDisabled: map.get($surface, "grey25");
$componentBackgroundError: map.get($surface, "white");
$componentBackgroundSuccess: map.get($surface, "white");
//TODO when have DM colours
$componentBackgroundDM: map.get($surface, "grey700");
$componentBackgroundFocusedDM: map.get($surface, "grey700");
$componentBackgroundFilledDM: map.get($surface, "grey700");
$componentBackgroundDisabledDM: map.get($surface, "grey600");
$componentBackgroundErrorDM: map.get($surface, "grey700");
$componentBackgroundSuccessDM: map.get($surface, "grey700");
$buttonBorder: #8696A7;
$coral: #eb6f7b;
$disabled: #6c757d;
$dolphinblue: #53b8b4;
$finn: #712e58;
$flax: #efcc89;
$gulfstream: #7ab4ab;
$highlight: #eef0f2;
$hoverLink: #e3173e;
$hyperlink: #099;
$icon: #afbac5;
$label_error: #e5173f;
$label_info: #53b8b4;
$keppel: #68b590;
$midnightblue: #3b3d64;
$mobileBackground: #f5f6f7;
$mobilePlaceholder: #546575;
$modalBackgroundMobile: #ffffff;
$modalBackgroundMobileDM: #202020;
$mojo: #c33f3b;
$moon: #f5eace;
$navy: #555b70;
$overlayBackgroundMobile: rgba(36, 44, 56, 0.5);
$overlayBackgroundMobileDM: rgba(18, 18, 18, 0.8);
$plum: #84497d;
$porcelain: #eef0f2;
$selected: #e2ecef;
$shiraz: #b00836;
$subtleText: #c0bfbf;
$sunflower: #e8ad44;
$surf: #b2cfbb;
$tango: #d5763f;
$text: #4b4e52;
$teal: #007A7A;
$viridiangreen: #099;
$warning: #C05200;
$title: #4b4e52;
$titleDM: #b4b1ad;
$viridiangreen: $hyperlink;
$white: #FFFFFF;
$whiteDM: #1c1c1c;
$grey1: #2c2e31;
$grey2: #373737;
$grey3: #808080;
$grey4: #bdbdbd;
$grey5: #dfdede;
$grey6: #f3f2f1;
$buttonBackground: #f8f8f8;
$blue: #1d70b8;
$darkblue: #003078;

/// Brand colour
///
/// @type Colour
/// @access public

$govuk-brand-colour: $access !default;

/// Text colour
///
/// @type Colour
/// @access public

$govuk-text-colour: $text !default;

/// Canvas background colour
///
/// Used by the footer component and template to give the illusion of a long
/// footer.
///
/// @type Colour
/// @access public

$govuk-canvas-background-colour: $white !default;


/// Body background colour
///
/// @type Colour
/// @access public

$govuk-body-background-colour: map.get($surface, "grey25") !default;

/// Text colour for print media
///
/// Use 'true black' to avoid printers using colour ink to print body text
///
/// @type Colour
/// @access public

$govuk-print-text-colour: $text !default;

/// Secondary text colour
///
/// Used in for example 'muted' text and help text.
///
/// @type Colour
/// @access public

$govuk-secondary-text-colour: map.get($surface, "grey900") !default;

/// Focus colour
///
/// Used for outline (and background, where appropriate) when interactive
/// elements (links, form controls) have keyboard focus.
///
/// @type Colour
/// @access public

$govuk-focus-colour: info($theme_option, "default") !default;

/// Focused text colour
///
/// Ensure that the contrast between the text and background colour passes
/// WCAG Level AA contrast requirements.
///
/// @type Colour
/// @access public

$govuk-focus-text-colour: map.get($surface, "grey25") !default;

/// Error colour
///
/// Used to highlight error messages and form controls in an error state
///
/// @type Colour
/// @access public

$govuk-error-colour: error($theme_option, "default") !default;

/// Success colour
///
/// Used to highlight success messages and banners
///
/// @type Colour
/// @access public

$govuk-success-colour: success($theme_option, "default") !default;

/// Border colour
///
/// Used in for example borders, separators, rules and keylines.
///
/// @type Colour
/// @access public

$govuk-border-colour: $border !default;

/// Input border colour
///
/// Used for form inputs and controls
///
/// @type Colour
/// @access public

$govuk-input-border-colour: map.get($surface, "grey200") !default;

/// Input hover colour
///
/// Used for hover states on form controls
///
/// @type Colour
/// @access public

$govuk-hover-colour: map.get($surface, "grey200") !default;

// =============================================================================
// Links
// =============================================================================

/// Link colour
///
/// @type Colour
/// @access public

$govuk-link-colour: $access !default;

/// Visited link colour
///
/// @type Colour
/// @access public

$govuk-link-visited-colour: $viridiangreen !default;

/// Link hover colour
///
/// @type Colour
/// @access public

$govuk-link-hover-colour: $hoverLink !default;

/// Active link colour
///
/// @type Colour
/// @access public

$govuk-link-active-colour: $hyperlink !default;

