@import '../colours';

 $govuk-button-colour: $teal;
 $govuk-button-hover-colour: govuk-shade($govuk-button-colour, 20%);
 $govuk-button-shadow-colour: govuk-shade($govuk-button-colour, 60%);
 $govuk-button-text-colour: govuk-colour("white");

 // Secondary button variables
 $govuk-secondary-button-colour: $grey6;
 $govuk-secondary-button-hover-colour: govuk-shade($govuk-secondary-button-colour, 10%);
 $govuk-secondary-button-shadow-colour: govuk-shade($govuk-secondary-button-colour, 40%);
 $govuk-secondary-button-text-colour: govuk-colour("black");

 // Warning button variables
 $govuk-warning-button-colour: $warning;
 $govuk-warning-button-hover-colour: govuk-shade($govuk-warning-button-colour, 20%);
 $govuk-warning-button-shadow-colour: govuk-shade($govuk-warning-button-colour, 60%);
 $govuk-warning-button-text-colour: govuk-colour("black");

.govuk-button {
    color: $govuk-button-text-colour;
    background-color: $govuk-button-colour;
    box-shadow: 0 2px 0 $govuk-button-shadow-colour;

    &__start-icon {
      display: inline-flex;
      margin-left: 0.25rem;
    }
    
    &:link,
    &:visited,
    &:active,
    &:hover {
      color: $govuk-button-text-colour;
      text-decoration: none;
    }

    &:hover {
      background-color: $govuk-button-hover-colour;
    }

    &:focus {
      border-color: $govuk-focus-colour;

      @include govuk-if-ie8 {
        color: $govuk-focus-text-colour;
        background-color: $govuk-focus-colour;
      }
      box-shadow: inset 0 0 0 1px $govuk-focus-colour;
    }

    @include govuk-compatibility(govuk_template) {
      &:link:focus {
        color: $govuk-button-text-colour;
      }
    }

    &:focus:not(:active):not(:hover) {
      border-color: $govuk-focus-colour;
      color: $govuk-focus-text-colour;
      background-color: $govuk-focus-colour;
      box-shadow: 0 2px 0 $govuk-focus-text-colour;
    }

    @include govuk-media-query($from: desktop) {
      width: 100%;
    }
}

.govuk-button--secondary {
  color: $govuk-secondary-button-text-colour;
  background-color: $govuk-secondary-button-colour;
  box-shadow: 0 2px 0 $govuk-secondary-button-shadow-colour;

  &:link,
    &:visited,
    &:active,
    &:hover {
      color: $govuk-secondary-button-text-colour;
      text-decoration: none;
    }

    &:hover {
      background-color: $govuk-secondary-button-hover-colour;
    }

    &:focus {
      border-color: $govuk-focus-colour;

      @include govuk-if-ie8 {
        color: $govuk-focus-text-colour;
        background-color: $govuk-focus-colour;
      }
      box-shadow: inset 0 0 0 1px $govuk-focus-colour;
    }

    @include govuk-compatibility(govuk_template) {
      &:link:focus {
        color: $govuk-secondary-button-text-colour;;
      }
    }

    &:focus:not(:active):not(:hover) {
      border-color: $govuk-focus-colour;
      color: $govuk-focus-text-colour;
      background-color: $govuk-focus-colour;
      box-shadow: 0 2px 0 $govuk-focus-text-colour;
    }
}