@import '../colours';

.govuk-select {
    border: $govuk-border-width-form-element solid $govuk-input-border-colour;
    color: $govuk-text-colour;
    background-color: "white";

    &:focus {
        outline: $govuk-focus-width solid $govuk-focus-colour;
    }
}

.govuk-select option:active,
.govuk-select option:checked,
.govuk-select:focus::-ms-value {
    color: $black;
    background-color: $denim;
}

.govuk-select--error {
    border-color: $label_error;

    &:focus {
        border-color: $govuk-focus-colour;
    }
}