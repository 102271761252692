@import './assets/scss/mixins';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-ms-reveal {
  display: none;
}

a {
  cursor: pointer;
}

.portal__show-password {
  position: relative;

  & input {
    padding: 0.3125rem 1.875rem 0.3125rem 0.625rem;
  }

  & img {
    cursor: pointer;
    position: absolute;
    width: 1.25rem;
    right: 0.5rem;
    top: 0.625rem;

    @include govuk-media-query($from: desktop) {
      top: 1rem;
    }
  }
}

.flex__text-align {
  &--right {
    display: flex;
    align-items: right;
    justify-content: right;

    @include govuk-media-query($from: desktop) {
      justify-content: right;
    }
  }
  
  &--left {
    display: flex;
    align-items: left;
    justify-content: left;
  }
  
  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--center-content-only {
    display: flex;
    align-items: normal;
    justify-content: center;
  }
}


.padding__left {
  &--lg {
    padding-left: 1rem;
  }

  &--m {
    padding-left: 0.5rem;
  }

  &--sm {
    padding-left: 0.25rem;
  }
  
}

.padding__right {
  &--lg {
    padding-right: 1rem;
  }

  &--m {
    padding-right: 0.5rem;
  }

  &--sm {
    padding-right: 0.25rem;
  }
}

.margin__top {
  &--lg {
    margin-top: 1.25rem;
  }

  &--m {
    margin-top: 0.625rem;
  }

  &--sm {
    margin-top: 0.313rem;
  }
}

.margin__bottom {
  &--lg {
    margin-bottom: 1.25rem;
  }

  &--m {
    margin-bottom: 0.625rem;
  }

  &--sm {
    margin-bottom: 0.313rem;
  }
}

.margin__top-bottom {
  &--lg {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  &--m {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  &--sm {
    margin-top: 0.313rem;
    margin-bottom: 0.313rem;
  }
}

.portal__wrapper--login {
  display: block;
  padding-top: govuk-spacing(0);
  padding-bottom: govuk-spacing(0);

  @include govuk-media-query($from: desktop) {
    padding-top: govuk-spacing(0);
    padding-bottom: govuk-spacing(0);
  }
}

.legend__fieldset {
  margin-bottom: 0.25rem;
  &-row{
    flex-direction: row;
    display: flex;
    line-height: 1.35rem;
  }
  &-hint {
    padding: 0;
  }
}

.checkbox__fieldset{
  margin-bottom: 0.9375rem;
  &-block {
    margin-left: 0.9375rem;
  }
}

.radio__fieldset {
  margin-left: 0.9375rem;
  margin-bottom: 1rem;
}

.signup {
  .govuk-link{
    padding: 0 0.5rem;
    text-decoration: none;
    &:focus {
      text-decoration: underline;
      border: none;
      border-color: transparent;
      outline: none;
      color: $access; 
      background-color: transparent;
      box-shadow: inset 0 0 0 1px transparent;
      padding: 0.6    rem;
    }
  }
}