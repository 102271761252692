@import '../colours';


.govuk-pagination__item--current {
    background-color: $govuk-link-colour;

    &:hover {
      background-color: $govuk-link-colour;
    }
}

.govuk-pagination__item--ellipses {
    color: $govuk-secondary-text-colour;

    &:hover {
      background-color: transparent;
    }
}

.govuk-pagination__link {
    &:focus {
      .govuk-pagination__icon {
        color: $black;
      }
    }
}

.govuk-pagination__icon {
    color: $govuk-secondary-text-colour;
    fill: currentcolor;
    padding:0 .5rem 0 .5rem;
}

.govuk-pagination--block {
    .govuk-pagination__prev + .govuk-pagination__next {
      border-top: 1px solid $govuk-border-colour;
    }

    .govuk-pagination__link {
      &:focus {
        .govuk-pagination__link-label {
          @include govuk-focused-text;
        }
      }
    }

}