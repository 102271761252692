@import '../colours';
    
.care__portal-panel {
    color: $black;
    background: $govuk-canvas-background-colour;
    -webkit-box-shadow: $shadow;
    -moz-osx-box-shadow: $shadow;
    box-shadow: $shadow;
    border-radius: 0.313rem;

    @include govuk-media-query($from: desktop) {
        padding: 2rem 10rem;

        & .govuk-panel__body {
            font-size: 1.125rem;
        }
    }
}
