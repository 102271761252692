@import '../colours';

.govuk-tabs__title {
    @include govuk-text-colour;
}

.govuk-tabs__list-item {
    &:before {
      @include govuk-text-colour;
    }
}

.js-enabled {
    @include govuk-media-query($from: tablet) {
      .govuk-tabs__list {
        border-bottom: 1px solid $govuk-border-colour;
      }

      .govuk-tabs__list-item {
        background-color: govuk-colour("light-grey", $legacy: "grey-4");
      }

      .govuk-tabs__list-item--selected {
        border: 1px solid $govuk-border-colour;
        background-color: $govuk-body-background-colour;
      }

      .govuk-tabs__panel {
        border: 1px solid $govuk-border-colour;
      }
    }
  }
