@import '../colours';

.govuk-table {
    @include govuk-text-colour;
}


.govuk-table__header,
.govuk-table__cell {
    border-bottom: 1px solid $govuk-border-colour;
}