@import '../colours';

.govuk-form-group {
    margin-bottom: 10px;
    
    @include govuk-media-query($from: desktop) {
        margin-bottom: 10px;
    }
}

.govuk-form-group--error {
    border-left: $govuk-border-width-form-group-error solid $label_error;
}
