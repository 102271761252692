@import '../colours';

$chevron-border-colour: $govuk-secondary-text-colour;

.govuk-back-link:before {
    border-color: $chevron-border-colour;

    @include govuk-if-ie8 {
      color: $chevron-border-colour;
    }
}

.govuk-back-link:focus:before {
    border-color: $govuk-focus-text-colour;
}