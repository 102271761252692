@import '../colours';

.govuk-notification-banner {
    border: $govuk-border-width solid $govuk-brand-colour;
    background-color: $govuk-brand-colour;

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
    }
}

.govuk-notification-banner__content {
    background-color: $govuk-body-background-colour;
}

.govuk-notification-banner--success {
    border-color: $govuk-success-colour;
    background-color: $govuk-success-colour;
}