@import '../colours';

$govuk-footer-background: $govuk-canvas-background-colour;
$govuk-footer-border: $govuk-border-colour;

$govuk-footer-border-top: $govuk-border-colour;
$govuk-footer-text: $govuk-text-colour;
$govuk-footer-link-hover-colour: null; 

@if $govuk-use-legacy-palette {
    $govuk-footer-border-top: $govuk-border-colour;
    $govuk-footer-border: $govuk-border-colour;
    $govuk-footer-text: $govuk-print-text-colour;
    $govuk-footer-link-hover-colour: $govuk-link-hover-colour;
}

.govuk-footer {
    border-top: 1px solid $govuk-footer-border-top;
    color: $govuk-footer-text;
    background: $govuk-footer-background;
}

.govuk-footer__link {
    @if $govuk-use-legacy-palette {
        &:link,
        &:visited {
            color: $govuk-footer-text;
        }

        &:hover,
        &:active {
            color: $govuk-footer-link-hover-colour;
        }
    } 
}

.govuk-footer__section-break {
    border-bottom: 1px solid $govuk-footer-border;
}

.govuk-footer__copyright-logo {
    display: inline-block;
    background-image: govuk-image-url("govuk-crest.png");
    @include govuk-device-pixel-ratio {
      background-image: govuk-image-url("govuk-crest-2x.png");
    }
    text-align: center;
    white-space: nowrap;
}

.govuk-footer__heading {
    border-bottom: 1px solid $govuk-footer-border;
}