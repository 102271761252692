@import '../colours';

$govuk-header-background: govuk-colour("white");
$govuk-header-border-color: $govuk-brand-colour;
$govuk-header-border-width: govuk-spacing(2);
$govuk-header-text: govuk-colour("black");
$govuk-header-link-active: #1d8feb;
$govuk-header-nav-item-border-color: #2e3133;
$govuk-header-link-underline-thickness: 3px;

.govuk-header {
    border-bottom: govuk-spacing(2) solid govuk-colour("white");
    color: $govuk-header-text;
    background: $govuk-header-background;
}

.govuk-header__container--full-width {
    border-color: $govuk-header-border-color;
}

.govuk-header__container {
  border-top: $govuk-header-border-width solid $govuk-header-border-color;
  border-bottom: none;
}

.govuk-header__link--homepage {
    @include govuk-media-query($from: tablet) {
      &:focus {
        box-shadow: 0 0 $govuk-focus-colour;
      }
    }
}

.govuk-header__menu-button {
    color: govuk-colour("white");
}

.govuk-header__navigation-item {
    border-bottom: 1px solid $govuk-header-nav-item-border-color;
}

.govuk-header__navigation-item--active {
    a {
      &:link,
      &:hover,
      &:visited {
        color: $govuk-header-link-active;
      }

      // When focussed, the text colour needs to be darker to ensure that colour
      // contrast is still acceptable
      &:focus {
        color: $govuk-focus-text-colour;
      }
    }
}

@include govuk-media-query($media-type: print) {
    .govuk-header {
      color: govuk-colour("black");
    }

    .govuk-header__link {
      &:link,
      &:visited {
        color: govuk-colour("black");
      }
    }
}